export const profileMockData: any = {
_id: "65fb04dcf835be9d0f1f3eea",
firstname: "Sourav",
lastname: "Dinda",
intro: "Goal-oriented & results-driven professional targeting assignments as AWS DevOps Engineer & SRE Engineer with an esteemed organization, across PAN India",
jobs: [
    "SRE | Senior AWS DevOps Engineer",
    "Certified Kubernetes Administrator",
    "AWS Certified Develover Associate",
    "AWS Certified Solution Architect",
    "GitLab Certified Associate",
    "Redhat Certified Engineer in Ansible"
    ],
bio: "<p>At <b>Cogito Corp</b> ( Product Based Company ) as <b>DevOps / SRE Engineer</b>, my expertise in <b>AWS and Kubernetes</b> has been pivotal in <b>migrating IAC from Cloudformation to Terraform</b>, enhancing the deployment process of <b>EKS manifest files via ArgoCD</b>, and refining <b>microservice management using Helm</b> . Our team has successfully transitioned applications from <b>ECS to EKS</b> , contributing to a more resilient and efficient cloud environment. I've <b>played a crucial role</b> in this evolution with my scripting acumen for <b>backend API development in FastAPI</b>  and <b>Flask</b>.</p></br></br><p>My education in Computer Science underpins my technical competencies, allowing me to architect 𝐫𝐨𝐛𝐮𝐬𝐭 𝐂𝐈/𝐂𝐃 𝐩𝐢𝐩𝐞𝐥𝐢𝐧𝐞𝐬 and deploy monitoring solutions such as 𝐆𝐫𝐚𝐟𝐚𝐧𝐚, 𝐏𝐫𝐨𝐦𝐞𝐭𝐡𝐞𝐮𝐬, 𝐚𝐧𝐝 𝐒𝐮𝐦𝐨 𝐋𝐨𝐠𝐢𝐜. These contributions have significantly improved our application's 𝐨𝐛𝐬𝐞𝐫𝐯𝐚𝐛𝐢𝐥𝐢𝐭𝐲 𝐚𝐧𝐝 𝐫𝐞𝐥𝐢𝐚𝐛𝐢𝐥𝐢𝐭𝐲. As a continuous learner and innovator, I am dedicated to leveraging my 𝐃𝐞𝐯𝐎𝐩𝐬 𝐬𝐤𝐢𝐥𝐥𝐬 𝐭𝐨 𝐟𝐨𝐬𝐭𝐞𝐫 𝐞𝐟𝐟𝐢𝐜𝐢𝐞𝐧𝐜𝐲 𝐚𝐧𝐝 𝐝𝐫𝐢𝐯𝐞 𝐭𝐞𝐜𝐡𝐧𝐨𝐥𝐨𝐠𝐢𝐜𝐚𝐥 𝐚𝐝𝐯𝐚𝐧𝐜𝐞𝐦𝐞𝐧𝐭𝐬 at Cogito Corp.</p>",
contactDetails: {
    address: "Haldia, East Medinipur, WB",
    email: "souravdindacse2@gmail.com",
    phone: "(+91) 7001458782"
    },
socialAccounts: {
    linkedIn: "https://www.linkedin.com/in/souravdinda-devops/",
    github: "https://github.com/souravdinda",
    facebook: "https://www.facebook.com/profile.php?id=61554684805412&mibextid=2JQ9oc",
    instagram: "https://www.instagram.com/sourav.dinda14"
    },
profileImageUrl: `${process.env.PUBLIC_URL}/images/profile-pic.gif`,
bioImageUrl: `${process.env.PUBLIC_URL}/images/bio-img.png`,
downloadCvUrl: "https://drive.google.com/file/d/15p7du_aHTwLzKGvNnzdBOe2oOErfuk2k/view?usp=sharing",
__v: 0,
autoEmailCredentials: {
    email: "noreply.souravdinda@gmail.com",
    passcode: "ftsc oqvd yoss sayv"
    },
email: "souravdindacse2@gmail.com",
updatedAt: "2024-04-17T18:24:56.538Z"
}