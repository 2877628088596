import Box from "@mui/material/Box";
import { EmailIcon, LocationIcon, PhoneIcon } from "./styles";
import { Grid, Typography } from "@mui/material";
import React from "react";

interface ContactItemProps {
  icon: React.ComponentType;
  field: string;
  value: string;
}
const ContactItem: React.FC<ContactItemProps> = ({
  icon: Icon,
  field,
  value,
}) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} margin={"6px 0"}>
      <Icon />
      <Box display={"flex"} flexDirection={"column"} pl={3}>
        <Typography variant="h4">{field}</Typography>
        <Typography variant="subtitle1">{value}</Typography>
      </Box>
    </Box>
  )
};

interface ContactDetailsProps {
  address: string;
  email: string;
  phone?: string;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  address,
  email,
  phone,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={4}>
        <ContactItem
          icon={LocationIcon}
          field="Address"
          value={address}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <ContactItem
          icon={EmailIcon}
          field="Email"
          value={email}
        />
      </Grid>
      {phone && <Grid item xs={12} sm={12} md={4}>
        <ContactItem
          icon={PhoneIcon}
          field="Phone"
          value={phone}
        />
      </Grid>}
    </Grid>
    // <Box display={"flex"} flexDirection={"row"} sx={{padding: "0 10px"}}>
    //   <ContactItem
    //     icon={LocationIcon}
    //     field="Address"
    //     value={address}
    //   />
    //   <ContactItem
    //     icon={EmailIcon}
    //     field="Email"
    //     value={email}
    //   />
    //   {phone &&
    //     <ContactItem
    //       icon={PhoneIcon}
    //       field="Phone"
    //       value={phone}
    //     />
    //   }
    // </Box>
  );
};

export default ContactDetails;
