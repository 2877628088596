export const resumeMockData: any = {
    _id: "65fb04fef835be9d0f1f3eed",
    profileId: "65fb04dcf835be9d0f1f3eea",
    workExperiences: [
        {
            organization: "Cogito Corp",
            jobs: [
                {
                    title: "SRE | Senior AWS DevOps Engineer",
                    startDate: "May,2024",
                    endDate: "Present",
                    jobtype: "Full time",
                    description: `● 𝐌𝐢𝐠𝐫𝐚𝐭𝐞𝐝 the Entire 𝐈𝐀𝐂 template from 𝐂𝐥𝐨𝐮𝐝𝐟𝐨𝐫𝐦𝐚𝐭𝐢𝐨𝐧 to 𝐓𝐞𝐫𝐫𝐚𝐟𝐨𝐫𝐦</br> 
● Managed 𝐌𝐢𝐜𝐫𝐨𝐬𝐞𝐫𝐯𝐢𝐜𝐞𝐬 using 𝐇𝐞𝐥𝐦 and Deployed 𝐄𝐊𝐒 manifests files using 𝐀𝐫𝐠𝐨𝐂𝐃 </br>
● modified 𝐅𝐚𝐬𝐭𝐀𝐏𝐈 scripts for 𝐛𝐚𝐜𝐤𝐞𝐧𝐝 𝐀𝐏𝐈 𝐝𝐞𝐯𝐞𝐥𝐨𝐩𝐞𝐦𝐞𝐧𝐭 and deployed in EKS</br>
● 𝐑𝐞𝐝𝐮𝐜𝐞𝐝 𝐝𝐞𝐩𝐥𝐨𝐲𝐦𝐞𝐧𝐭 𝐭𝐢𝐦𝐞 𝐛𝐲 30% 𝐚𝐧𝐝 𝐢𝐦𝐩𝐫𝐨𝐯𝐞𝐝 𝐫𝐞𝐬𝐨𝐮𝐫𝐜𝐞 𝐜𝐨𝐧𝐬𝐮𝐦𝐩𝐭𝐢𝐨𝐧 of components by tweaking Ansible playbooks and Terraform</br>
● Moved Some Applications from 𝐄𝐂𝐒 𝐭𝐨 𝐄𝐊𝐒</br>
● Created 𝐀𝐧𝐬𝐢𝐛𝐥𝐞 𝐏𝐥𝐚𝐲𝐛𝐨𝐨𝐤 as Configuration as a Code. </br>
● Configured 𝐆𝐫𝐚𝐟𝐚𝐧𝐚 𝐚𝐧𝐝 𝐏𝐫𝐨𝐦𝐞𝐭𝐡𝐞𝐮𝐬 for Metrics Monitoring</br>
● Configured 𝐒𝐮𝐦𝐨 𝐋𝐨𝐠𝐢𝐜 𝐟𝐨𝐫 𝐭𝐡𝐞 𝐚𝐩𝐩𝐥𝐢𝐜𝐚𝐭𝐢𝐨𝐧'𝐬 𝐥𝐨𝐠 𝐦𝐚𝐧𝐚𝐠𝐞𝐦𝐞𝐧𝐭.`
                }
            ]
        },
        {
            organization: "Tata Consultancy Services",
            jobs: [
                {
                    title: "AWS DevOps Engineer",
                    startDate: "Aug,2021",
                    endDate: "May,2024",
                    jobtype: "Full time",
                    description: `● Deployed Microservices application in 𝐄𝐊𝐒 𝐜𝐥𝐮𝐬𝐭𝐞𝐫 𝐟𝐨𝐫 𝐇𝐢𝐠𝐡 𝐚𝐯𝐚𝐢𝐥𝐚𝐛𝐢𝐥𝐢𝐭𝐲 𝐚𝐧𝐝 𝐦𝐚𝐢𝐧𝐭𝐞𝐧𝐚𝐧𝐜𝐞.</br>
● Responsible for creating solutions to 𝐦𝐢𝐠𝐫𝐚𝐭𝐞 𝐨𝐧-𝐩𝐫𝐞𝐦 𝐚𝐩𝐩𝐬 𝐨𝐧𝐭𝐨 𝐀𝐖𝐒. </br>
● 𝐀𝐮𝐭𝐨𝐦𝐚𝐭𝐞 CRUD method using API by leveraging 𝐀𝐏𝐈 𝐆𝐚𝐭𝐞𝐰𝐚𝐲, 𝐋𝐚𝐦𝐛𝐝𝐚, 𝐚𝐧𝐝 𝐃𝐲𝐧𝐚𝐦𝐨𝐝𝐛</br>
● End-to-end 𝐉𝐞𝐧𝐤𝐢𝐧𝐬 𝐌𝐮𝐥𝐭𝐢-𝐛𝐫𝐚𝐧𝐜𝐡 𝐩𝐢𝐩𝐞𝐥𝐢𝐧𝐞 from Maven build to deploy in 𝐊𝐮𝐛𝐞𝐫𝐧𝐞𝐭𝐞𝐬 with high availability</br>
● Deployed EKS manifests file using 𝐆𝐢𝐭𝐎𝐩𝐬 𝐭𝐨𝐨𝐥 𝐧𝐚𝐦𝐞𝐝 𝐅𝐥𝐮𝐱𝐂𝐃</br>
● Responsible for 𝐜𝐫𝐞𝐚𝐭𝐢𝐧𝐠 𝐥𝐚𝐦𝐛𝐝𝐚 𝐬𝐜𝐫𝐢𝐩𝐭𝐬 that 𝐦𝐚𝐧𝐝𝐚𝐭𝐞 𝐜𝐨𝐦𝐩𝐥𝐢𝐚𝐧𝐜𝐞 𝐚𝐧𝐝 𝐦𝐨𝐧𝐢𝐭𝐨𝐫 𝐡𝐮𝐦𝐚𝐧 𝐞𝐫𝐫𝐨𝐫𝐬 𝐭𝐡𝐚𝐭 𝐦𝐚𝐲 𝐜𝐚𝐮𝐬𝐞 𝐬𝐞𝐜𝐮𝐫𝐢𝐭𝐲 𝐟𝐥𝐚𝐰𝐬.</br>
● Responsible for creating scripts that 𝐜𝐨𝐥𝐥𝐞𝐜𝐭, 𝐩𝐫𝐨𝐜𝐞𝐬𝐬, 𝐚𝐧𝐝 𝐬𝐞𝐧𝐝 𝐥𝐨𝐠 𝐝𝐚𝐭𝐚 𝐨𝐟 𝐚𝐥𝐥 𝐀𝐖𝐒 𝐚𝐜𝐜𝐨𝐮𝐧𝐭𝐬 𝐭𝐨 𝐒𝐩𝐥𝐮𝐧𝐤 for monitoring and analysis. </br>
● Responsible for designing and developing 𝐀𝐖𝐒 𝐢𝐧𝐟𝐫𝐚𝐬𝐭𝐫𝐮𝐜𝐭𝐮𝐫𝐞 𝐚𝐮𝐭𝐨𝐦𝐚𝐭𝐢𝐨𝐧 including deployment and monitoring for our infrastructure.</br> 
● Created 𝐓𝐞𝐫𝐫𝐚𝐟𝐨𝐫𝐦 Scripts from scratch for IAC</br>
● Setting up monitoring with 𝐒𝐩𝐥𝐮𝐧𝐤, 𝐀𝐩𝐩𝐝𝐲𝐧𝐚𝐦𝐢𝐜𝐬, 𝐃𝐲𝐧𝐚𝐭𝐫𝐚𝐜𝐞</br>
● Writing 𝐆𝐢𝐭𝐥𝐚𝐛 𝐂𝐈𝐂𝐃 pipelines. </br>
● Created 𝐅𝐚𝐬𝐭𝐀𝐏𝐈 𝐟𝐫𝐚𝐦𝐞𝐰𝐨𝐫𝐤 𝐟𝐨𝐫 𝐛𝐚𝐜𝐤𝐞𝐧𝐝 𝐝𝐞𝐯𝐞𝐥𝐨𝐩𝐦𝐞𝐧𝐭.</br>
● Built 𝐅𝐥𝐚𝐬𝐤 framework for different use cases.</br>
● Served as an 𝐒𝐌𝐄 𝐟𝐨𝐫 𝐀𝐖𝐒 𝐃𝐞𝐯𝐎𝐩𝐬 𝐄𝐧𝐠𝐢𝐧𝐧𝐞𝐫</br>
● Developed 𝐏𝐲𝐭𝐡𝐨𝐧 𝐜𝐨𝐝𝐞 to fulfill client’s Automation requirements that 𝐬𝐚𝐯𝐞𝐝 $20𝐤 (𝐃𝐨𝐥𝐥𝐚𝐫) 𝐦𝐨𝐧𝐭𝐡𝐥𝐲`
                }
            ]
        }
//         {
//             organization: "Red Hat",
//             jobs: [
//                 {
//                     title: "Red Hat Certified OpenShift Administrator",
//                     startDate: "Dec,2021",
//                     endDate: "Dec,2022",
//                     jobtype: "Trainee",
//                     description: `● Configure 𝐚𝐮𝐭𝐡𝐞𝐧𝐭𝐢𝐜𝐚𝐭𝐢𝐨𝐧 𝐰𝐢𝐭𝐡 𝐭𝐡𝐞 𝐇𝐓𝐏𝐚𝐬𝐬𝐰𝐝 𝐢𝐝𝐞𝐧𝐭𝐢𝐭𝐲 𝐩𝐫𝐨𝐯𝐢𝐝𝐞𝐫 and assign 𝐫𝐨𝐥𝐞𝐬 𝐭𝐨 𝐮𝐬𝐞𝐫𝐬 𝐚𝐧𝐝 𝐠𝐫𝐨𝐮𝐩𝐬</br>
// ● Deploy and 𝐮𝐩𝐝𝐚𝐭𝐞 𝐚𝐩𝐩𝐥𝐢𝐜𝐚𝐭𝐢𝐨𝐧𝐬 𝐟𝐫𝐨𝐦 𝐫𝐞𝐬𝐨𝐮𝐫𝐜𝐞 𝐦𝐚𝐧𝐢𝐟𝐞𝐬𝐭𝐬 that are parameterized for 𝐝𝐢𝐟𝐟𝐞𝐫𝐞𝐧𝐭 𝐭𝐚𝐫𝐠𝐞𝐭 𝐞𝐧𝐯𝐢𝐫𝐨𝐧𝐦𝐞𝐧𝐭𝐬</br>
// ● Expose 𝐚𝐩𝐩𝐥𝐢𝐜𝐚𝐭𝐢𝐨𝐧𝐬 𝐭𝐨 𝐞𝐱𝐭𝐞𝐫𝐧𝐚𝐥 𝐚𝐜𝐜𝐞𝐬𝐬</br>
// ● 𝑼𝒑𝒅𝒂𝒕𝒆 𝒂𝒏 𝑶𝒑𝒆𝒏𝑺𝒉𝒊𝒇𝒕 𝒄𝒍𝒖𝒔𝒕𝒆𝒓 and 𝐦𝐢𝐧𝐢𝐦𝐢𝐳𝐞 𝐝𝐢𝐬𝐫𝐮𝐩𝐭𝐢𝐨𝐧 to deployed applications</br>
// ● Access an OpenShift cluster from the command line, and 𝐪𝐮𝐞𝐫𝐲 𝐢𝐭𝐬 𝐊𝐮𝐛𝐞𝐫𝐧𝐞𝐭𝐞𝐬 𝐀𝐏𝐈 𝐫𝐞𝐬𝐨𝐮𝐫𝐜𝐞𝐬 to assess the health of a cluster</br>
// ● 𝐭𝐫𝐨𝐮𝐛𝐥𝐞𝐬𝐡𝐨𝐨𝐭 𝐜𝐨𝐧𝐭𝐚𝐢𝐧𝐞𝐫𝐢𝐳𝐞𝐝 𝐚𝐩𝐩𝐥𝐢𝐜𝐚𝐭𝐢𝐨𝐧𝐬</br>
// ● Deploy applications and expose them to network access from inside and outside a Kubernetes cluster</br>
// ● Configure applications to work with 𝐊𝐮𝐛𝐞𝐫𝐧𝐞𝐭𝐞𝐬 𝐟𝐨𝐫 𝐡𝐢𝐠𝐡 𝐚𝐯𝐚𝐢𝐥𝐚𝐛𝐢𝐥𝐢𝐭𝐲 𝐚𝐧𝐝 𝐫𝐞𝐬𝐢𝐥𝐢𝐞𝐧𝐜𝐞</br>
// ● Manage reproducible 𝐚𝐩𝐩𝐥𝐢𝐜𝐚𝐭𝐢𝐨𝐧 𝐮𝐩𝐝𝐚𝐭𝐞𝐬 𝐚𝐧𝐝 𝐫𝐨𝐥𝐥𝐛𝐚𝐜𝐤𝐬 𝐨𝐟 𝐜𝐨𝐝𝐞 𝐚𝐧𝐝 𝐜𝐨𝐧𝐟𝐢𝐠𝐮𝐫𝐚𝐭𝐢𝐨𝐧𝐬</br>`
//                 }
//             ]
//         },
//         {
//             organization: "Red Hat",
//             jobs: [
//                 {
//                     title: "Red Hat Certified Engineer",
//                     startDate: "Nov,2020",
//                     endDate: "Aug,2021",
//                     jobtype: "Trainee",
//                     description: `● Create an inventory of managed hosts, write an Ansible playbook, and run the 𝐩𝐥𝐚𝐲𝐛𝐨𝐨𝐤 𝐭𝐨 𝐚𝐮𝐭𝐨𝐦𝐚𝐭𝐞 𝐭𝐚𝐬𝐤𝐬 𝐨𝐧 𝐭𝐡𝐨𝐬𝐞 𝐡𝐨𝐬𝐭𝐬.</br>
// ● Write playbooks that are 𝐨𝐩𝐭𝐢𝐦𝐢𝐳𝐞𝐝 𝐟𝐨𝐫 𝐥𝐚𝐫𝐠𝐞𝐫, 𝐦𝐨𝐫𝐞 𝐜𝐨𝐦𝐩𝐥𝐞𝐱 𝐩𝐥𝐚𝐲𝐬 𝐚𝐧𝐝 𝐩𝐥𝐚𝐲𝐛𝐨𝐨𝐤𝐬</br>
// ● 𝐓𝐫𝐨𝐮𝐛𝐥𝐞𝐬𝐡𝐨𝐨𝐭 𝐩𝐥𝐚𝐲𝐛𝐨𝐨𝐤𝐬 𝐚𝐧𝐝 𝐦𝐚𝐧𝐚𝐠𝐞𝐝 𝐡𝐨𝐬𝐭𝐬</br>
// ● Automate 𝐜𝐨𝐦𝐦𝐨𝐧 𝐋𝐢𝐧𝐮𝐱 𝐬𝐲𝐬𝐭𝐞𝐦 𝐚𝐝𝐦𝐢𝐧𝐢𝐬𝐭𝐫𝐚𝐭𝐢𝐨𝐧 𝐭𝐚𝐬𝐤𝐬 𝐰𝐢𝐭𝐡 𝐀𝐧𝐬𝐢𝐛𝐥𝐞</br>
// ● Write playbooks that use 𝐯𝐚𝐫𝐢𝐚𝐛𝐥𝐞𝐬 𝐭𝐨 𝐬𝐢𝐦𝐩𝐥𝐢𝐟𝐲 𝐭𝐡𝐞 𝐦𝐚𝐧𝐚𝐠𝐞𝐦𝐞𝐧𝐭 𝐨𝐟 𝐭𝐡𝐞 𝐩𝐥𝐚𝐲𝐛𝐨𝐨𝐤 𝐚𝐧𝐝 𝐟𝐚𝐜𝐭𝐬 to reference information about managed hosts`
//                 }
//             ]
//         }
    ],
    technicalSkills: [
        {
            name: "AWS Cloud",
            rating: 9,
            _id: "65fb04fef835be9d0f1f3ef6"
        },
        {
            name: "Docker",
            rating: 8,
            _id: "65fb04fef835be9d0f1f3eee"
        },
        {
            name: "Kubernetes",
            rating: 8,
            _id: "65fb04fef835be9d0f1f3eef"
        },
        {
            name: "ArgoCD / FluxCD",
            rating: 8,
            _id: "65fb04fef835be9d0f1f3ef7"
        },
        {
            name: "Jenkins",
            rating: 7,
            _id: "65fb04fef835be9d0f1f3ef0"
        },
        {
            name: "Terraform",
            rating: 9,
            _id: "65fb04fef835be9d0f1f3ef1"
        },
        {
            name: "Ansible",
            rating: 8,
            _id: "65fb04fef835be9d0f1f3ef7"
        },
        {
            name: "Git",
            rating: 8,
            _id: "65fb04fef835be9d0f1f3ef2"
        },
        {
            name: "Gitlab CI/CD",
            rating: 9,
            _id: "65fb04fef835be9d0f1f3ef3"
        },
        {
            name: "Linux",
            rating: 8,
            _id: "65fb04fef835be9d0f1f3ef4"
        },
        {
            name: "Python/FastAPI/Flask",
            rating: 9,
            _id: "65fb04fef835be9d0f1f3ef5"
        },
        {
            name: "Openshift",
            rating: 7,
            _id: "65fb04fef835be9d0f1f3ef7"
        },
        {
            name: "Grafana",
            rating: 7,
            _id: "65fb04fef835be9d0f1f3ef7"
        },
        {
            name: "Prometheus",
            rating: 7,
            _id: "65fb04fef835be9d0f1f3ef7"
        },
        {
            name: "Splunk",
            rating: 7,
            _id: "65fb04fef835be9d0f1f3ef8"
        }
    ],
    educations: [
        {
            institution: "College of Engineering & Management, Kolaghat",
            degree: "Bachelor of Technology",
            stream: "Computer Science",
            startYear: "2017",
            endYear: "2021"
        }
    ],
    certifications: [
        {
            name: "Certified Kubernetes Administrator",
            issuer: "Linux Foundation",
            issuedOn: "April 2024"
        },
        {
            name: "Red Hat Certified OpenShift Administrator",
            issuer: "Redhat",
            issuedOn: "March 2024"
        },
        {
            name: "AWS Certified Developer Associate",
            issuer: "Amazon Web Services (AWS)",
            issuedOn: "May 2022"
        },
        {
            name: "AWS Certified Solutions Architect - Associate",
            issuer: "Amazon Web Services (AWS)",
            issuedOn: "Jun 2023"
        },
        {
            name: "Redhat Certified Specialist in Container & Kubernetes ",
            issuer: "Red Hat",
            issuedOn: "Feb 2022"
        },
        {
            name: "Gitlab Certified Associate",
            issuer: "GitLab",
            issuedOn: "Nov 2021"
        },
        {
            name: "Redhat certified specialist in Ansible Automation",
            issuer: "Red Hat",
            issuedOn: "Aug 2021"
        }
    ],
    __v: 0,
    updatedAt: "2024-04-17T18:37:06.271Z"
}